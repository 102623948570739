import { PageStaticList } from './type'

const pageListCompany: PageStaticList = {
  companyHome: {
    text: '会議一覧',
    url: '/company/meetings',
  },
  companyChangePassword: {
    text: 'パスワードを変更する',
    url: '/company/change-password',
  },
  companyChangeProfile: {
    text: 'プロフィール変更',
    url: '/company/update-profile',
  },
}

export default pageListCompany
