import clsx from 'clsx'
import { observer } from 'mobx-react-lite'
import Head from 'next/head'
import { useEffect } from 'react'
import { useAuthStore } from 'src/stores/hooks'

import { AppFooter } from './app-footer'
import AppHeader from './app-header'
import MenuMobile from './app-header/menu-mobile'

type Props = {
  title?: string
  minWidth?: boolean
  noMargin?: boolean
  noPadding?: boolean
  noMenu?: boolean
  className?: string
  breadcrumbClassName?: string
  showBreadcrumbMobile?: boolean
  noHeader?: boolean
}

export default observer(function AppLayout({
  children,
  className,
  minWidth = true,
  noPadding = false,
}: React.PropsWithChildren<Props>): JSX.Element {
  const authStore = useAuthStore()

  useEffect(() => {
    authStore.checkAuth()
  }, [])

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0,user-scalable=0" />
      </Head>
      <div className="flex flex-col min-h-[100vh]">
        <AppHeader />
        <main
          className={clsx('flex-1 py-10 px-2 md:py-[60px] md:px-20', {
            '!overflow-x-scroll': minWidth,
            'md:px-0 px-0': noPadding,
          })}
        >
          <MenuMobile />
          <div
            className={clsx('page-layout-children', className, {
              '!min-w-[950px]': minWidth,
            })}
          >
            {children}
          </div>
        </main>
        <AppFooter />
      </div>

      <style jsx>{`
        .logo-mobile {
          font-weight: 800 !important;
          font-size: 36px;
          line-height: 49px;
          letter-spacing: 0.1em;
        }
        @media (max-width: 575.98px) {
          :global(.page-layout.ant-layout) {
            justify-content: center;
          }
        }
        .menu-item {
          font-size: 0.6875rem;
          font-weight: 700;
          padding: 0rem 1.75rem;
          position: relative;
          margin-bottom: 25px;
        }
        .menu-link {
          color: #003b54;
          font-size: 14px;
          font-weight: 500;
        }
      `}</style>
    </>
  )
})
