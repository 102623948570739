import { ExportOutlined, KeyOutlined, UserOutlined } from '@ant-design/icons'
import { Dropdown, Menu } from 'antd'
import { observer } from 'mobx-react-lite'
import Link from 'next/link'
import Router from 'next/router'
import MenuIcon from 'public/svg/menu.svg'
import awsmobile from 'src/aws-exports'
import { checkRedirect } from 'src/common/helpers'
import pageListAdmin from 'src/common/helpers/page/admin'
import pageListCompany from 'src/common/helpers/page/company'
import { useAuthStore, useLayoutStore } from 'src/stores/hooks'
import { ROLE } from 'src/types/role'

import {useSessionManager} from "../../../common/contexts/session_manager";

const AppHeader = () => {
  const store = useLayoutStore()
  const auth = useAuthStore()
  const sessionManager = useSessionManager()
  let accountName
  if (typeof window !== 'undefined') {
    accountName = localStorage.getItem(
      `CognitoIdentityServiceProvider.${awsmobile.aws_user_pools_web_client_id}.LastAuthUser`
    )
  }

  const handleLogout = async () => {
    await auth.logout().then(() => {
      sessionManager.setUserSession({
        cci_code: null,
        username: null,
        user_type: null,
      })
      sessionStorage.clear()
      location.href = '/'
    })
  }

  const handlePushToChangePasswordPage = () => {
    const { role } = auth
    if (role === ROLE.SUPER_ADMIN || role === ROLE.ADMIN) {
      Router.push({
        pathname: `${pageListAdmin.adminChangePassword.url}`,
      })
    } else if (role === ROLE.COMPANY) {
      Router.push({
        pathname: `${pageListCompany.companyChangePassword.url}`,
      })
    }
  }

  const handlePushToChangeProfilePage = () => {
    const { role } = auth
    if (role === ROLE.SUPER_ADMIN || role === ROLE.ADMIN) {
      Router.push({
        pathname: `${pageListAdmin.adminChangeProfile.url}`,
      })
    } else if (role === ROLE.COMPANY) {
      Router.push({
        pathname: `${pageListCompany.companyChangeProfile.url}`,
      })
    }
  }

  const items = [
    {
      label: 'プロフィール変更',
      icon: <UserOutlined />,
      onClick: () => handlePushToChangeProfilePage(),
      key: 'changeProfile',
    },
    {
      label: 'パスワード変更',
      icon: <KeyOutlined />,
      onClick: () => handlePushToChangePasswordPage(),
      key: 'changePassword',
    },
    {
      label: 'ログアウト',
      icon: <ExportOutlined />,
      onClick: () => handleLogout(),
      key: 'logout',
    },
  ]

  const menu = <Menu items={items}></Menu>

  return (
    <header className="header flex h-[60px] justify-between items-center p-5 bg-[#4169E1] shadow-[0_4_20_rgba(0,0,0,0.1)] relative">
      <div className="flex justify-between items-center w-full">
        <div className="flex items-center gap-2">
          <Link href={checkRedirect(auth.role) ?? '/login'}>
            <picture>
              <img src={'/images/logo-lg.png'} className="md:w-[290px] md:h-[50px] w-[200px]" alt="logo" />
            </picture>
          </Link>
          <p className="hidden lg:block text-base text-white font-bold">{auth.companyName}</p>
        </div>
        <div className="hidden">
          <p className="text-xs text-white font-bold">
            試験運用中
            <br />
            ページの表示に時間が掛かる場合がございます
          </p>
        </div>
        <div className="lg:hidden">
          <a className="cursor-pointer" onClick={store.open}>
            <MenuIcon />
          </a>
        </div>
      </div>
      <div className="hidden w-full">
        <p className="text-base text-white font-bold">
          試験運用中
          <br />
          ページの表示に時間が掛かる場合がございます
        </p>
      </div>
      <div className="hidden lg:block">
        <div className="flex items-center">
          <Link
              href="https://drive.google.com/file/d/1Lx6VtTjP0f7NIT8CcgoZCvfQT9NOEUOy/view?usp=share_link"
              target="_blank"
              className="text-base text-white font-bold w-[max-content] mr-[60px]"
          >
            ヘルプ
          </Link>
        {!!auth.isLoggedIn && (
            <Dropdown.Button overlay={menu} trigger={['click']}>
              {accountName}
            </Dropdown.Button>
        )}
      </div>
      </div>
      <p className="absolute bottom-[-40px] md:bottom-[-55px] left-9 md:left-20 lg:hidden text-xl md:text-3xl text-primary font-bold">
        {auth.companyName}
      </p>
    </header>
  )
}

export default observer(AppHeader)
