import dayjs from 'dayjs'

export function AppFooter({ children }: React.PropsWithChildren): JSX.Element {
  return (
    <>
      <footer className="footer-container bg-[#4169E1] h-[50px] flex items-center justify-center">
        <p className="text-xs text-[#fff]">{`© ${dayjs().year()} YCCI CONFERENCE`}</p>
        {children}
      </footer>
    </>
  )
}
