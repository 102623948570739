import { PageStaticList } from './type'

const pageListAdmin: PageStaticList = {
  adminHome: {
    text: 'ホーム',
    url: '/admin',
  },
  adminListCompany: {
    text: '企業管理',
    url: '/admin/master/companies',
  },
  adminCreateCompany: {
    text: '企業登録',
    url: '/admin/master/companies/create',
  },
  adminOfficer: {
    text: '議員管理',
    url: '/admin/master/officer-management',
  },
  adminOfficerListCompany: {
    text: '議員登録',
    url: '/admin/master/officer-management/companies',
  },
  adminMeetingList: {
    text: '会議管理',
    url: '/admin/meetings',
  },
  adminCreateMeeting: {
    text: '会議登録',
    url: '/admin/meetings/create',
  },
  adminTemplateMeetingList: {
    text: '会議テンプレート一覧',
    url: '/admin/template-meetings',
  },
  adminCreateTemplateMeeting: {
    text: '会議テンプレート登録・編集',
    url: '/admin/template-meetings/create',
  },
  adminListExport: {
    text: 'ファイルダウンロード',
    url: '/admin/exports',
  },
  adminChangePassword: {
    text: 'パスワードを変更する',
    url: '/admin/change-password',
  },
  adminChangeProfile: {
    text: 'プロフィール変更',
    url: '/admin/update-profile',
  },
}

export default pageListAdmin
