import { CloseCircleOutlined } from '@ant-design/icons'
import clsx from 'clsx'
import { observer } from 'mobx-react-lite'
import Router from 'next/router'
import { useAuthStore, useLayoutStore } from 'src/stores/hooks'

import pageListAdmin from '../../../common/helpers/page/admin'
import pageListCompany from '../../../common/helpers/page/company'
import { ROLE } from '../../../types/role'

const MenuMobile = () => {
  const store = useLayoutStore()
  const auth = useAuthStore()

  const handlePushToChangePasswordPage = () => {
    const { role } = auth
    if (role === ROLE.SUPER_ADMIN || role === ROLE.ADMIN) {
      Router.push({
        pathname: `${pageListAdmin.adminChangePassword.url}`,
      })
    } else if (role === ROLE.COMPANY) {
      Router.push({
        pathname: `${pageListCompany.companyChangePassword.url}`,
      })
    }
    store.close()
  }

  const handlePushToChangeProfilePage = () => {
    const { role } = auth
    if (role === ROLE.SUPER_ADMIN || role === ROLE.ADMIN) {
      Router.push({
        pathname: `${pageListAdmin.adminChangeProfile.url}`,
      })
    } else if (role === ROLE.COMPANY) {
      Router.push({
        pathname: `${pageListCompany.companyChangeProfile.url}`,
      })
    }
    store.close()
  }

  return (
    <div
      className={clsx('page-mobile-menu flex justify-end', {
        open: store.isOpen,
      })}
    >
      <div className="page-mobile-menu-wrapper py-3">
        <div className="mb-[60px]flex items-center">
          <button className="p-3" onClick={store.close}>
            <CloseCircleOutlined width={20} height={20} />
          </button>
        </div>

        <div className="page-mobile-menu-content">
          <ul className="menu-content">
            <li className="menu-item">
              {!!auth.isLoggedIn && (
                <>
                  <a className="menu-link flex items-center" onClick={handlePushToChangeProfilePage}>
                    <div className="ml-2 text-sm font-medium leading-[20px]">プロフィール変更</div>
                  </a>
                  <a className="menu-link flex items-center" onClick={handlePushToChangePasswordPage}>
                    <div className="ml-2 text-sm font-medium leading-[20px]">パスワード変更</div>
                  </a>
                </>
              )}
              <a
                href="https://drive.google.com/file/d/1Lx6VtTjP0f7NIT8CcgoZCvfQT9NOEUOy/view?usp=share_link"
                target="_blank"
                className="menu-link flex items-center "
                rel="noreferrer"
              >
                <div className="ml-2 mb-2 text-sm font-medium leading-[20px]">ヘルプ</div>
              </a>
              {!!auth.isLoggedIn && (
                <>
                  <a
                    className="menu-link flex items-center"
                    onClick={() => {
                      auth.logout()
                      store.close()
                    }}
                  >
                    <div className="ml-2 text-sm font-medium leading-[20px] text-[#ff0000]">ログアウト</div>
                  </a>
                </>
              )}
            </li>
          </ul>
        </div>
      </div>

      <style jsx>
        {`
          .page-mobile-menu {
            position: fixed;
            right: -200%;
            top: 0;
            bottom: 0;
            transition: all 0.3s ease-in-out;
            z-index: 999;
            width: 100%;
          }

          .page-mobile-menu.open {
            right: 0;
          }

          .page-mobile-menu.open::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(51, 51, 51, 0.5);
            pointer-events: none;
          }

          .page-mobile-menu-wrapper {
            width: 90%;
            max-width: 15rem;
            max-height: 100vh;

            z-index: 99;
            position: relative;
            overflow-y: auto;
            background-color: #ffffff;
            padding-bottom: 0.625rem;
          }

          .page-mobile-menu-content {
            width: 100%;
            height: calc(100% - 6.875rem);
          }

          .page-mobile-menu-header {
            background-color: #faf9f8;
            display: flex;
            align-items: center;
            height: 3.125rem;
            padding: 0 1.25rem;
          }

          @media (min-width: 1024px) {
            .page-desktop-menu {
              display: block;
              width: 17.5rem;
            }

            .page-mobile-menu {
              display: none;
            }
          }
        `}
      </style>
    </div>
  )
}

export default observer(MenuMobile)
